<template>
<div class="refund-operator-container">
  <!-- <Item>
    <ItemSection class="header-title">
      <h1>Não conseguimos identificar qual é a sua operadora.</h1>
      <h2>Selecione abaixo:</h2>
    </ItemSection>
  </Item> -->
  <div>
    <Item class="operator-card" v-for="(operator, o) in operators" :key="o" @click.native="$emit('open', operator)">
      <ItemSection>
        {{operator.name}}
      </ItemSection>
      <ItemSection side>
        <img src="/img/icons/arrow-point-to-right.svg" width="14px">
      </ItemSection>
    </Item>
  </div>
</div>
</template>
<script>
import Item from '../../components/structure/item/item'
import ItemSection from '../../components/structure/item/itemSection'
export default {
  name: 'RefundOperatorSelect',
  components: {
    Item,
    ItemSection
  },
  props: {
    operators: {
      type: Array,
      default: x => []
    }
  }
}
</script>
<style>
.refund-operator-container{
  background: #fff;
}
.header-title{
  padding: 16px;
  color: #4a499b;
}
.header-title h1{
  font-size: 28px;
  line-height: 1.3;
  margin-bottom: 10px;
}
.header-title h2{
  font-weight: 100;
}
.header-title h3{
  font-size: 18px;
  color: #4a499b;
  line-height: 1;
}
.header-title h5{
  font-size: 14px;
  color: #8e8e9f;
  line-height: 1.5;
}
.operator-card{
  background: #f8f8ff;
  border-radius: 16px;
  margin: 22px 12px;
  padding: 22px;
  box-shadow: 0 8px 22px rgba(0, 0, 0, .2);
}
</style>