<template>
  <div>
    <Item v-for="(item, i) in value" :key="i">
      <ItemSection side>
        <div :class="['dotNumber', (i + 1) < value.length ? 'dotLine' : '']"> {{i + 1}} </div>
      </ItemSection>
      <ItemSection class="dotLabel">
        {{item}}
      </ItemSection>
    </Item>
  </div>
</template>
<script>
import Item from './item/item'
import ItemSection from './item/itemSection'
export default {
  name: 'NumberList',
  components: {
    Item,
    ItemSection
  },
  props: {
    value: {
      type: Array,
      default: v => []
    }
  }
}
</script>
<style>
.dotNumber{
  border-radius: 100%;
  background: #22377e;
  line-height: 36px;
  width: 36px;
  height: 36px;
  text-align: center;
  color: white;
  margin-right: 16px;
  font-size: 20px;
  font-weight: 900;
  position: relative;
}
.dotLine.dotNumber::before{
  content: '';
  border: 1px solid #22377e;
  position: absolute;
  top: 36px;
  left: 17px;
  height: 50px;
}
</style>
