<template lang="pug">
  .item-section(:class="side ? 'item-section-side' : 'item-section-default'")
    slot
</template>
<script>
export default {
  name: 'ItemSection',
  props: {
    side: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style>
.item-section-side{
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  z-index: 100002;
}
.item-section-default {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-width: 0;
}
</style>
