<template lang="pug">
  .item-main(:class="classes")
    slot
</template>
<script>
export default {
  name: 'Item',
  props: {
    noPadding: Boolean
  },
  computed: {
    classes () {
      const classes = []
      if (this.noPadding) classes.push('no-padding')
      return classes
    }
  }
}
</script>
<style scoped>
.item-main {
  padding: 16px;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.no-padding{
  padding: 0;
}
</style>
